import FarmIcon from '@mui/icons-material/AgricultureOutlined';
import DateIcon from '@mui/icons-material/DateRangeOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { farmVisitFieldTypes } from '../../constants/enums';
import { useSettings } from '../../hooks';
import { Score } from '../shared';

const { TEXT, BOOLEAN, SCORE, CHECKBOX, RADIO } = farmVisitFieldTypes;

const FarmVisitResult = (props) => {
  const { farmsList = [], data = {}, formData } = props;
  const { Farm_ID, OADate, FarmVisitAnswers = [] } = data;

  const { t } = useTranslation();
  const { formatDate } = useSettings();

  const farmName = useMemo(() => {
    const farm = farmsList?.find((f) => f.farm_id === Farm_ID);
    return farm?.farm_name || '';
  }, [Farm_ID, farmsList]);

  const renderAnswer = (questionId, type) => {
    const answer = FarmVisitAnswers?.find((a) => a.FarmVisitQuestionId === questionId);

    if (!answer || answer.Value === null || answer.Value === undefined) {
      return '';
    }

    const value = answer.Value;

    switch (type) {
      case TEXT:
        return <Typography component='div'>{value}</Typography>;
      case BOOLEAN:
        return <Typography component='div'>{value ? t('yes') : t('no')}</Typography>;
      case SCORE:
        return <Score disabled value={value} />;
      case CHECKBOX:
        return value?.length ? (
          <Box component='div'>
            {value?.map((v) => (
              <Box component='div' key={`${Math.random()}_${v}`}>
                {`\u2022 ${v}`}
              </Box>
            ))}
          </Box>
        ) : (
          ''
        );
      case RADIO:
        return <Typography component='div'>{value}</Typography>;
      default:
        return null;
    }
  };

  return (
    <Box component='div' sx={{ width: '100%', pb: 2 }}>
      {!!data && !!formData && (
        <Box component='div'>
          <Box component='div' sx={{ display: 'flex', alignItems: 'center' }}>
            <FarmIcon color='secondary' fontSize='small' sx={{ mr: '4px' }} />
            <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
              {t('farm')}:
            </Typography>
            <Typography component='span'>{farmName}</Typography>
          </Box>
          <Box component='div' sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <DateIcon color='secondary' fontSize='small' sx={{ mr: '4px' }} />
            <Typography component='span' sx={{ mr: 1, fontWeight: 'bold' }}>
              {t('date')}:
            </Typography>
            <Typography component='span'>{formatDate(OADate, true)}</Typography>
          </Box>

          <Box component='div' sx={{ mt: 3 }}>
            {sortBy(formData?.FarmVisitSections, ['Order'])?.map((section) => (
              <Accordion
                defaultExpanded
                key={section.id}
                sx={{
                  background: 'none',
                  boxShadow: 'none',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  mt: 2,
                  '::before': { display: 'none' },
                }}
              >
                <AccordionSummary
                  aria-controls={`panel-${section.id}-content`}
                  expandIcon={<ExpandMoreIcon />}
                  id={`panel-${section.id}`}
                >
                  <Box component='div'>
                    <Box component='div' sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography component='div' sx={{ fontWeight: 'bold', fontSize: 18 }}>
                        {section.Title}
                      </Typography>
                    </Box>
                    <Typography
                      component='div'
                      sx={{ color: (theme) => theme?.palette?.primary?.greyDark, fontSize: 14 }}
                    >
                      {section.Description}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {sortBy(
                    section?.FarmVisitQuestions?.filter((q) =>
                      FarmVisitAnswers?.find((a) => a.FarmVisitQuestionId === q.id)
                    ),
                    ['Order']
                  )?.map((question, index) => (
                    <Paper
                      key={question.id}
                      sx={{
                        mb: 2,
                        p: 2,
                        borderLeft: (theme) => `4px solid ${theme?.palette?.primary?.main}`,
                      }}
                    >
                      <Typography component='div' sx={{ display: 'flex' }}>
                        <Typography component='span' sx={{ fontWeight: 'bold' }}>
                          {`${index + 1}. ${question.Title}`}
                        </Typography>
                      </Typography>
                      <Typography
                        component='div'
                        sx={{ color: (theme) => theme?.palette?.primary?.greyDark, fontSize: 14 }}
                      >
                        {question.Description}
                      </Typography>
                      <Box component='div' sx={{ mt: 1 }}>
                        {renderAnswer(question.id, question.Type)}
                      </Box>
                    </Paper>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FarmVisitResult;
