import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TableIcon from '@mui/icons-material/TableRowsOutlined';
import TreeIcon from '@mui/icons-material/TocOutlined';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getResults } from '../../../actions/farmVisitActions';
import { FarmVisitResultsTree, FarmVisitResult } from '../../../components/farm-visit';
import LoadScreen from '../../../components/load-screen';
import { BottomNavigation, SideMenu, Skeleton } from '../../../components/shared';
import NoResults from '../../../components/shared/no-results';
import { routes } from '../../../constants';
import { useLoading } from '../../../hooks';
import { farmVisitResultsSelector, farmsSelector } from '../../../reducers/selectors';
import farmVisitService from '../../../services/farmVisitService';
import { containerPadding } from '../../../styles/theme/shared';

const tabs = {
  TREE: 0,
  RESULTS: 1,
};

const FarmVisitResultsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const farmVisitResults = useSelector(farmVisitResultsSelector);
  const { results = [], isLoaded } = farmVisitResults;

  const farms = useSelector(farmsSelector);
  const { farmsList = [] } = farms;

  const { loading, startLoading, stopLoading } = useLoading(true);

  const [collapsed, setCollapse] = useState(true);
  const [currentPage, setCurrentPage] = useState(tabs.TREE);
  const [selectedQuestionnaire, selectQuestionnaire] = useState(null);
  const [selectedResult, selectResult] = useState(null);
  const [fetching, setFetching] = useState(false);

  const onLoad = async () => {
    try {
      startLoading();
      await dispatch(getResults());
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    onLoad().catch(console.error);
  }, []);

  const onClick = async (obj) => {
    try {
      setFetching(true);
      const body = {
        OADate: obj.OADate,
        Farm_ID: obj.Farm_ID,
        FarmVisitQuestionnaireId: obj.Questionnaire_ID,
      };

      const form = await farmVisitService.getFarmVisitFormById(obj.Questionnaire_ID);
      const formResult = await farmVisitService.getFarmVisitResult(body);

      selectQuestionnaire(form?.questionnaire || null);
      selectResult(formResult?.fv_result || null);
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const onClear = () => {
    selectQuestionnaire(null);
    selectResult(null);
  };

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const getMobileStyles = (pg) => ({
    display: currentPage === pg ? 'block' : 'none',
    p: {
      sm: '8px 16px 48px',
      xs: '8px 0 48px',
    },
  });

  const navigationTabs = [
    {
      value: tabs.TREE,
      label: t('tree'),
      icon: <TreeIcon />,
    },
    {
      value: tabs.TABLE,
      label: t('results'),
      icon: <TableIcon />,
      disabled: !selectedResult || !selectedQuestionnaire || fetching,
    },
  ];

  return (
    <>
      {loading && !isLoaded && <LoadScreen />}
      <Container
        maxWidth={false}
        sx={{
          p: {
            lg: 0,
            md: 0,
            sm: 0,
            sx: 0,
          },
          position: 'relative',
        }}
      >
        <div className='feeds-page'>
          {mobileView ? (
            <>
              <Box component='div' sx={getMobileStyles(tabs.TREE)}>
                <Box component='div' sx={{ pb: 2 }}>
                  <FarmVisitResultsTree
                    mobileView={mobileView}
                    onClear={onClear}
                    onClick={onClick}
                    results={results}
                    selected={selectedResult}
                  />
                  {isLoaded && !results?.length && (
                    <NoResults text={t('farmVisit.noResults')} url={routes.FARM_VISIT_NEW} />
                  )}
                </Box>
              </Box>
              <Box component='div' sx={getMobileStyles(tabs.RESULTS)}>
                <Typography
                  component='h6'
                  sx={{
                    margin: '8px 0 16px',
                    fontWeight: 'bold',
                  }}
                  variant='h6'
                >
                  {t('farmVisit.resultsTitle')}
                </Typography>
                {!!selectedResult && !!selectedQuestionnaire && (
                  <FarmVisitResult data={selectedResult} farmsList={farmsList} formData={selectedQuestionnaire} />
                )}
              </Box>
              <BottomNavigation
                activeTab={currentPage}
                onChange={(event, newValue) => {
                  setCurrentPage(newValue);
                }}
                tabs={navigationTabs}
              />
            </>
          ) : (
            <>
              <SideMenu collapsed={collapsed} setCollapse={setCollapse}>
                <FarmVisitResultsTree
                  mobileView={mobileView}
                  onClear={onClear}
                  onClick={onClick}
                  results={results}
                  selected={selectedResult}
                />
              </SideMenu>
              <Container maxWidth={false} sx={{ p: containerPadding }}>
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box component='div' sx={{ width: '100%' }}>
                    <h2>{t('farmVisit.resultsTitle')}</h2>
                    {isLoaded && !results?.length && (
                      <Box sx={{ mb: 2 }}>
                        <NoResults text={t('farmVisit.noResults')} url={routes.FARM_VISIT_NEW} />
                      </Box>
                    )}
                    {!selectedResult && !collapsed && (
                      <Box component='div' sx={{ width: 'fit-content' }}>
                        <MuiAlert icon={<ArrowBackIcon />} severity='warning' variant='outlined'>
                          {t('farmVisit.warningAlert')}
                        </MuiAlert>
                      </Box>
                    )}
                    {fetching ? (
                      <Box component='div'>
                        <Skeleton rows={8} />
                      </Box>
                    ) : (
                      !!selectedResult &&
                      !!selectedQuestionnaire && (
                        <Box component='div' sx={{ display: 'flex' }}>
                          <FarmVisitResult
                            data={selectedResult}
                            farmsList={farmsList}
                            formData={selectedQuestionnaire}
                          />
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
              </Container>
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default FarmVisitResultsPage;
