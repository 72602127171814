const AUTH_URL = `${process.env.REACT_APP_API_URL}/auth`;
export const SSO_URL = `${process.env.REACT_APP_SSO_URL}`;
export const PROJECTS_URL = `${process.env.REACT_APP_SSO_URL}/projects`;
const WEB_URL = '/web';
const BCS_URL = '/bcs';
const PSC_FEED_URL = '/shaker/feed';
const PSC_TMR_URL = '/shaker/tmr';
const FARM_VISIT = '/farm_visit';

const urls = {
  auth: {
    signIn: `${AUTH_URL}/signin`,
  },
  user: {
    farms: `${WEB_URL}/users_farms`,
    farmsRation: `${WEB_URL}/farms_rations_ng`,
    settings: `${WEB_URL}/user_settings`,
    rations: `${WEB_URL}/feeds_outputs_ng`,
    templates: `${WEB_URL}/templates`,
    nutrients: `${WEB_URL}/ration_outputs`,
    mixes: `${WEB_URL}/mix_viewer_index_ng`,
    feeds: `${WEB_URL}/mix_views_ng`,
    feedsList: `${WEB_URL}/feed_view_index_ng`,
    feedView: `${WEB_URL}/feed_view`,
  },
  admin: {
    activityLog: `${WEB_URL}/activity_logs`,
    farmsAccess: `${WEB_URL}/mills`,
    millUsers: `${WEB_URL}/mill_farms_users`,
    addExtUser: `${WEB_URL}/add_permissions`,
    permissions: `${WEB_URL}/permissions`,
  },
  bcs: {
    initLoad: `${BCS_URL}/init_load`,
    create: BCS_URL,
    farms: `${BCS_URL}/farms`,
    view: `${BCS_URL}/view`,
  },
  particleSize: {
    feed: PSC_FEED_URL,
    tmr: PSC_TMR_URL,
    farms: `${PSC_FEED_URL}/farms`,
    tmrList: `${PSC_TMR_URL}/farms`,
    view: `${PSC_FEED_URL}/view`,
    viewTMR: `${PSC_TMR_URL}/view`,
  },
  adminFarmVisit: {
    create: `${FARM_VISIT}/questionnaire/new`,
    get: `${FARM_VISIT}/questionnaire`,
    questionnaires: `${FARM_VISIT}/questionnaires`,
    getByFarm: `${FARM_VISIT}/farms`,
    getTemplate: `${FARM_VISIT}/questionnaire_template`,
  },
  farmVisit: {
    create: `${FARM_VISIT}/result/new`,
    getResults: `${FARM_VISIT}/farms`,
    getResult: `${FARM_VISIT}/result`,
  },
};

export default urls;
