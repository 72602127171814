import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../input';

const SearchInput = (props) => {
  const { value = '', onChange = () => {}, handleClear = () => {}, size, placeholder } = props;

  const { t } = useTranslation();

  return (
    <Input
      id='search-input'
      label={t('search')}
      name='searchInput'
      onChange={onChange}
      placeholder={placeholder}
      size={size}
      value={value}
      {...props}
      endAdornment={
        <InputAdornment
          position='end'
          sx={{
            marginLeft: '-24px',
            position: 'absolute',
          }}
        >
          {value.length > 0 && (
            <IconButton aria-label='Clear input' edge='end' onClick={handleClear} size='small'>
              <ClearIcon fontSize='small' />
            </IconButton>
          )}
        </InputAdornment>
      }
    />
  );
};

SearchInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  handleClear: PropTypes.func,
};

export default SearchInput;
